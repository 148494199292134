// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".point-store__two-column-item{border-radius:var(--goods-border-radius);box-shadow:0 1px 2px 0 rgba(0,0,0,.05);height:-moz-fit-content;height:fit-content;overflow:hidden}.point-store__two-column-item .thumb{height:122px}.point-store__two-column-item .thumb .color-bg{border-radius:var(--goods-border-radius) var(--goods-border-radius)}.point-store__two-column-item .info .name{color:var(--main-text-color);font-size:12px;font-weight:700;margin-top:8px;padding:0 10px}.point-store__two-column-item .info .limit{border:1px solid var(--main-text-color);border-radius:var(--goods-border-radius);color:var(--main-text-color);font-size:10px;margin:10px 12px;padding:2px 4px;width:-moz-fit-content;width:fit-content}.point-store__two-column-item .info .off-sale{color:var(--second-text-color);font-size:10px;margin-top:8px;padding:0 10px}.point-store__two-column-item .pay-btn{margin:10px 0;padding:0 10px}.point-store__two-column-item .pay-btn .price-block{background-color:var(--button-bg-color);line-height:32px;text-align:center}.point-store__two-column-item .pay-btn .price-block,.point-store__two-column-item .pre-btn{border-radius:var(--goods-border-radius);color:var(--button-text-color);font-size:12px;width:100%}.point-store__two-column-item .pre-btn{align-items:center;background-color:rgba(0,0,0,.3);display:flex;height:32px;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
