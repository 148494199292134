<template>
  <div class="point-store__two-column-list relative grid grid-cols-2 gap-4">
    <list-item v-for="(item, index) in list" :key="'child' + index" :item="item" :model="model" @exchange-success="$emit('exchange-success')" />
  </div>
</template>


<script>
import ListItem from './item.vue'
export default {
  name: 'VipPointTwoColumnList',
  components: {
    ListItem
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="less">
.point-store__two-column-list {
  padding: 14px 14px 0 14px;
}
</style>
