<template>
  <base-dialog v-model="visible" width="520px" class="save-confirm" type="warning" title="更改未保存，是否退出页面？">
    <div class="save-confirm__content">
      <div class="mt-6 text-center text-black text-16">离开此页面将删除所有未保存的更改。</div>
      <div class="flex items-center justify-end py-4 px-5 gap-5">
        <button class="normal-btn" @click.stop="close">留下</button>
        <button class="remove-btn" @click.stop="handleQuit">离开页面</button>
      </div>
    </div>
  </base-dialog>
</template>

<script>
import BaseDialog from "~/components/base/baseDialog.vue";
export default {
  name: 'SaveConfirm',
  components: {BaseDialog},
  data() {
    return {
      visible: false
    }
  },
  methods: {
    init() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    handleQuit() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="less">
.save-confirm {
  &__content {
    .normal-btn {
      background-color: white;
      line-height: 32px;
      padding: 0 10px;
      color: black;
      border: 1px solid #dedede;
      border-radius: 2px;
    }
    .remove-btn {
      background-color: @danger-color;
      line-height: 32px;
      padding: 0 10px;
      color: white;
      border: 1px solid @danger-color;
      border-radius: 2px;
    }
  }
}
</style>
