<template>
  <div class="point-store__list grid grid-cols-5 gap-[45px] mt-10">
    <list-item v-for="(item, index) in list" :key="'child' + index" :item="item" :editing="editing" :model="model" @exchange-success="$emit('exchange-success')" />
  </div>
</template>

<script>
import ListItem from './item.vue'
export default {
  name: 'VipPointsStorePcList',
  components: {
    ListItem
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default() {
        return []
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="less">

</style>
