<template>
  <div class="item-warp">
    <div :style="item.style" class="vip-user__swiper-item relative item" :class="{ 'is-mobile': $store.getters.isMobile }">
      <img :src="rankIconMap[item.level]" alt="" class="absolute right-[30px] vip-icon"/>
      <div class="content">
        <div class="title">
          <div>
            <span>{{ item.name }}</span>
            <template v-if="vipUserInfo.level === item.level">
              <span class="current-level">{{ $t('siteBuild.vipUserInfoWidget.currentLevel') }}</span>
            </template>
          </div>
          <div v-if="vipUserInfo.level === item.level" class="text-[12px] mt-2 font-normal">{{ vipExpiryTime }} {{ $t('siteBuild.expiry') }}</div>
        </div>
        <div v-if="(vipUserInfo.level < item.level || vipUserInfo.level > item.level) || editing" class="mt-2">
          {{ $t('siteBuild.vipUserInfoWidget.growthTip') }}：{{ item.growth }}
        </div>
        <div v-if="vipUserInfo.level > item.level" class="mt-2">{{ $t('siteBuild.vipUserInfoWidget.overLevel') }}</div>
        <div v-if="vipUserInfo.level === item.level" class="progress">
          <div class="mb-2">
            <span>{{ $t('siteBuild.growth') }}</span>
            <span class="current-growth">
              {{ vipUserInfo.growth }}
            </span>
          </div>
          <el-progress :percentage="(vipUserInfo.growth / (item.nextLevelGrowth || item.growth)) * 100" :show-text="false" :stroke-width="6" color="var(--color-3)"/>
          <div v-if="item.nextLevelGrowth" class="flex items-center justify-between mt-1">
            <div>
              <span class="font-bold">V{{ item.level }}</span> {{ $t('siteBuild.vipUserInfoWidget.updateTip', { growth: item.nextLevelGrowth - vipUserInfo.growth }) }}
            </div>
            <div class="font-bold">V{{ item.level + 1 }}</div>
          </div>
          <div v-else class="text-[14px] font-bold mt-1">{{ $t('siteBuild.maxTip') }}</div>
        </div>
      </div>
      <div class="rights-block">
        <img class="bg" src="@/assets/img/rights-bg.png" alt="">
        <div class="right-area relative px-8">
          <div v-if="item.rights.rights?.[0]?.value" class="right-item mb-4 flex items-center justify-between">
            <div class="flex items-center">
              <img src="@/assets/img/right-icon.png" class="w-[24px] h-[24px]" alt="">
              <span class="ml-2">{{ $t('siteBuild.vipUserInfoWidget.pointScale') }}</span>
            </div>
            <div>{{ item.rights.rights[0].value }}</div>
          </div>
          <div v-if="item.rights.packages?.[0]?.points"  class="right-item flex items-center justify-between">
            <div class="flex items-center">
              <img src="@/assets/img/right-icon.png" class="w-[24px] h-[24px]" alt="">
              <span class="ml-2">{{ $t('siteBuild.vipUserInfoWidget.giftPoints') }}</span>
            </div>
            <div>{{ item.rights.packages[0].points }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Progress } from 'element-ui'
import moment from "moment";
import V1Icon from '@/assets/img/v1.png'
import V2Icon from '@/assets/img/v2.png'
import V3Icon from '@/assets/img/v3.png'
import V4Icon from '@/assets/img/v4.png'
import V5Icon from '@/assets/img/v5.png'
import V6Icon from '@/assets/img/v6.png'
import V7Icon from '@/assets/img/v7.png'
import V8Icon from '@/assets/img/v8.png'

export default {
  name: 'CardItem',
  components: {
    [Progress.name]: Progress
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => Object.freeze({})
    }
  },
  data() {
    return {
      rankIconMap: {
        1: V1Icon,
        2: V2Icon,
        3: V3Icon,
        4: V4Icon,
        5: V5Icon,
        6: V6Icon,
        7: V7Icon,
        8: V8Icon
      }
    }
  },
  computed: {
    vipUserInfo() {
      return this.$store.state.user.vipUserInfo
    },
    vipExpiryTime() {
      return moment(this.vipUserInfo.level_expire_time * 1000).format('YYYY-MM-DD')
    }
  },
  methods: {

  }
}
</script>

<style lang="less">
.vip-user__swiper-item {
  height: 260px;
  .content {
    padding: 12px;
  }
  .vip-icon {
    width: 140px;
    top: -40px;
  }
  .title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    .current-level {
      font-size: 20px;
      font-weight: 300;
    }
  }
  .progress {
    margin-top: 10px;
    width: 350px;
  }
  .current-growth {
    font-size: 22px;
  }
  .rights-block {
    width: 560px;
    height: 202px;
    position: absolute;
    bottom: -116px;
    left: -20px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    .right-area {
      padding-top: 80px;
      .right-item {
        font-size: 16px;
        border-bottom: 1px solid var(--text-color-60);
        padding-bottom: 12px;
        color: var(--main-text-color);
      }
    }
    .bg {
      width: 100%;
      height: 260px;
      position: absolute;
      top: 0;
      left: 0;
      backdrop-filter: blur(20px);
    }
  }
  &.is-mobile {
    height: 190px;
    .progress {
      margin-top: 8px;
      width: 100%;
      font-size: 12px;
    }
    .current-growth {
      font-size: 12px;
    }
    .rights-block {
      width: calc(100% + 20px);
      left: -10px;
      bottom: -106px;
      height: 160px;
      .right-item {
        font-size: 12px;
      }
      .right-area {
        padding: 40px;
      }
    }
    .content {
      padding: 16px;
    }
    .title {
      font-size: 30px;
    }
    .vip-icon {
      width: 96px;
      height: 96px;
      top: -30px;
    }
    .current-level {
      font-size: 16px;
    }
  }
}
</style>
