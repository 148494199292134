<template>
  <!-- 全局主题 -->
  <div class="global-setting-widget">
    <!-- 背景设置 -->
    <menu-item :label="$t('edit.bgSetting')">
      <menu-item :left-circle="true" class="menu-item-background" :label="$t('edit.bg') + $t('edit.color')">
        <menu-fill class="mb-8px" :target="model" color-key="backgroundColor" eye-key=""
          @change="(color) => model.setBackgroundColor(color)"></menu-fill>
      </menu-item>

      <menu-item :left-circle="true" class="menu-item-background" :label="$t('edit.bg') + $t('menu.image')">
        <div class="mb-2 text-white text-12">
          {{ $t('common.pc') }}
          <span class="text-textColor-3">({{ $t('upload.suggestionExtra', { w: 16, h: 9 }) }})</span>
        </div>
        <menuBackgroundImage key="2" :target="model" :eye-key="'backgroundImageShow'"></menuBackgroundImage>
      </menu-item>

      <menu-item class="mt-2 menu-item-background">
        <div class="mb-2 text-white text-12">
          {{ $t('common.mobile') }}
          <span class="text-textColor-3">({{ $t('upload.suggestionExtra', { w: 3, h: 8 }) }})</span>
        </div>
        <menuBackgroundImage key="1" :target="model.mobileBackground" :eye-key="'backgroundImageShow'">
        </menuBackgroundImage>
      </menu-item>
    </menu-item>
    <menu-divider></menu-divider>

    <!--按钮-->
    <menu-item :label="$t('edit.button')">
      <menu-background color-circle blur-circle :image-disabled="false" :background-name="$t('edit.buttonFill')"
        label-size="normal" :fill-eye="false" :background-blur-disabled="true"
        :background-blur-name="$t('menu.radiusDialog')" :model="model" color-key="btnColor" image-key="btnImage"
        image-size-key="btnImageSize" image-opacity-key="btnImageOpacity" image-eye-key="btnImageShow"
        @change="(color) => model.setBtnColor(color)" @image-change="(val) => model.setBtnImage(val)"
        @image-visible-change="(val) => model.setBtnImageShow(val)"
        @image-opacity-change="(val) => model.setBtnImageOpacity(val)" />
      <menu-item :left-circle="true" class="menu-item-background" :label="$t('edit.buttonText')">
        <menu-fill class="mb-8px" :target="model" color-key="btnTextColor" eye-key=""
          @change="(color) => model.setBtnTextColor(color)"></menu-fill>
      </menu-item>
      <menu-item :left-circle="true" class="menu-item-background" :label="$t('edit.buttonRadius')">
        <Slider v-model="model.btnBorderRadius" @change="(val) => model.setBtnBorderRadius(val)" />
      </menu-item>
    </menu-item>
    <menu-divider></menu-divider>

    <!-- 文本颜色 -->
    <menu-item :label="$t('menu.textColor')">
      <menu-fill class="mb-8px" :target="model" color-key="globalColor" eye-key=""
        @change="(color) => model.setGlobalColor(color)"></menu-fill>
    </menu-item>
    <menu-divider></menu-divider>
    <menu-item label="购物车">
      <menu-switch v-model="model.shopCartEnabled" label="是否开启购物车" target-key="shopCartEnabled"></menu-switch>
    </menu-item>
    <menu-item v-if="model.shopCartEnabled" label="购物车按钮颜色" size-tip="(建议比例4:3)">
      <menu-fill :target="model.shopCartColor" class="mt-2" target-key="shopCartColor"/>
    </menu-item>
    <menu-item v-if="model.shopCartEnabled" label="购物车按钮背景" size-tip="(建议比例4:3)">
      <menu-background-image :target="model.shopCartBackground" target-key="shopCartBackground"/>
      <menu-fill :target="model.shopCartBackground" class="mt-2" target-key="shopCartBackground"/>
    </menu-item>
  </div>
</template>

<script>
import { GlobalSettingModel } from '../../model'
import MenuItem from "~/components/menu/menuItem";
import MenuFill from '~/components/menu/menuFill.vue'
import MenuDivider from '~/components/menu/divider'
import Slider from "~/components/menu/menuSlider";
import menuBackgroundImage from '~/components/menu/menuBackgroundImage.vue'
import MenuBackground from '~/components/menu/menuBackground.vue'
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  name: "GlobalSettingWidgetEdit",
  components: {
    MenuSwitch,
    MenuItem,
    MenuFill,
    Slider,
    MenuDivider,
    menuBackgroundImage,
    MenuBackground,
    // MenuButton
  },
  props: {
    model: {
      type: Object,
      default() {
        return new GlobalSettingModel()
      }
    }
  },
  created() {
    //
  },
  methods: {
    handleShowBackgroundMenu() {
      this.SiteMenu.open({
        key: 'global-background',
        target: this.model
      })
      // this.$parent && this.$parent.selectSubmenu('global-background')
    }
  }

}
</script>

<style lang="less" scoped>
.colors {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .color-item {
    width: calc(50% - 4px);

    //&:nth-child(2n){
    //  margin-left: 8px;
    //}
    p {
      padding-left: 14px;
      position: relative;
      margin-bottom: 6px;

      &:before {
        position: absolute;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        top: 8px;
        left: 0;
        background: @primary-color;
      }
    }
  }
}
</style>
