<template>
  <div class="actions-bar h-full flex flex-col items-center justify-center gap-4">
    <div
      v-for="item in handleList" :key="item.type"
      class="actions-bar__item w-[32px] h-[32px] rounded-full bg-white flex items-center justify-center hover:bg-textColor-7"
      :class="{ 'disabled': (item.type === 'up' && disabledUp) || (item.type === 'down' && disabledDown) || (item.type === 'delete' && disabledDelete) }"
      @click.stop="item.handle"
    >
      <i class="text-black item-icon text-18" :class="item.icon" />
    </div>
  </div>
</template>

<script>
import { scrollTo } from '~/utils/dom'
export default {
  components: {
  },
  props: {
    selectWidget: {
      type: Object,
      default() {
        return {}
      }
    },
    widgetList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      handleList: [
        {
          type: 'up',
          icon: 'el-icon-top',
          disabled: false,
          handle: this.handleUp
        },
        {
          type: 'down',
          icon: 'el-icon-bottom',
          disabled: false,
          handle: this.handleDown
        },
        {
          type: 'delete',
          icon: 'el-icon-delete',
          disabled: false,
          handle: this.handleDelete
        }
      ]
    }
  },
  computed: {
    disabledUp() {
      let result = false
      const { selectWidget, widgetList } = this
      const current = selectWidget?.id || null
      if (!current) {
        result = true
      } else {
        const index = widgetList.findIndex(item => item.id === current)
        const prev = widgetList[index - 1]
        const prevInfo = prev?.getMenuInfo() || {}
        const curInfo = selectWidget?.getMenuInfo() || {}
        result = prev === undefined || (prevInfo.isDefault && !prevInfo.draggable) || (curInfo.isDefault && !curInfo.draggable)
      }
      return result
    },
    disabledDown() {
      let result = false
      const { selectWidget, widgetList } = this
      const current = selectWidget?.id || null
      if (!current) {
        result = true
      } else {
        const index = widgetList.findIndex(item => item.id === current)
        const next = widgetList[index + 1]
        const nextInfo = next?.getMenuInfo() || {}
        const curInfo = selectWidget?.getMenuInfo() || {}
        result = next === undefined || (nextInfo.isDefault && !nextInfo.draggable) || (curInfo.isDefault && !curInfo.draggable)
      }
      return result
    },
    disabledDelete() {
      let result = false
      const { selectWidget } = this
      if (!selectWidget) {
        result = true
      } else {
        const curInfo = selectWidget?.getMenuInfo() || {}
        result = curInfo.isDefault
      }
      return result
    }
  },
  methods: {
    handleUp() {
      const { selectWidget, widgetList } = this
      const index = widgetList.findIndex(item => item.id === selectWidget.id)
      const prev = index - 1
      this.$emit('up', index, prev)
      this.scrollToView()
    },
    handleDown() {
      const { selectWidget, widgetList } = this
      const index = widgetList.findIndex(item => item.id === selectWidget.id)
      const next = index + 1
      this.$emit('down', index, next)
      this.scrollToView()
    },
    scrollToView() {
      this.$nextTick(() => {
        const item = this.selectWidget
        if (item.id) {
          scrollTo(`${item.id}`, '.edit-content__center--wrapper')
        }
      })
    },
    handleDelete() {
      this.SiteMenu.openDeleteBlockDialog()
    }
  }
}
</script>

<style lang="less">
.actions-bar {
  &__item {
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      .item-icon {
        opacity: 0.2;
      }
    }
  }
}
</style>
