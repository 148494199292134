<template>
  <div class="flex-select flex items-center justify-between">
    <div v-if="options.includes(StoreWidgetV2FlexLayoutEnum.ONE_COLUMN)" class="item" :class="{ 'active': val === StoreWidgetV2FlexLayoutEnum.ONE_COLUMN }" @click="handleSelect('1')">
      <div class="w-[45px] h-[25px] box-color" />
      <div class="text-12 text-center pt-2">单列</div>
    </div>
    <div v-if="options.includes(StoreWidgetV2FlexLayoutEnum.TWO_COLUMN)" class="item" :class="{ 'active': val === StoreWidgetV2FlexLayoutEnum.TWO_COLUMN }" @click="handleSelect('2')">
      <div class="flex gap-1">
        <div class="w-[25px] h-[25px] box-color" />
        <div class="w-[25px] h-[25px] box-color" />
      </div>
      <div class="text-12 text-center pt-2">双列</div>
    </div>
    <div v-if="options.includes(StoreWidgetV2FlexLayoutEnum.ROW_COLUMN)" class="item" :class="{ 'active': val === StoreWidgetV2FlexLayoutEnum.ROW_COLUMN }" @click="handleSelect('3')">
      <div class="flex gap-1">
        <div class="w-[25px] h-[25px] box-color" />
        <div class="w-[25px] h-[25px] box-color" />
        <div class="w-[15px] h-[25px] box-color" />
      </div>
      <div class="text-12 text-center pt-2">横滑</div>
    </div>
  </div>
</template>

<script>
import {StoreWidgetV2FlexLayoutEnum} from "~/enums/layoutEnum";

export default {
  name: 'FlexSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [
          StoreWidgetV2FlexLayoutEnum.ONE_COLUMN,
          StoreWidgetV2FlexLayoutEnum.TWO_COLUMN,
          StoreWidgetV2FlexLayoutEnum.ROW_COLUMN
        ]
      }
    }
  },
  data() {
    return {
      StoreWidgetV2FlexLayoutEnum,
      current: '1',
      val: this.value
    }
  },
  methods: {
    handleSelect(value) {
      this.val = value
      this.$emit('input', this.val)
    }
  }
}
</script>

<style lang="less">
.flex-select {
  .item {
    cursor: pointer;
    padding: 4px;
    border-radius: 2px;
    border: 1px solid transparent;
    .box-color {
      background-color: rgba(255, 255, 255, 0.4);
    }
    &.active {
      border: 1px solid @primary-color;
    }
  }
}
</style>
