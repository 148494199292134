// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card-swiper__content{padding-bottom:120px}.card-swiper__content .item-warp{margin:0 30px}.card-swiper__content .item-warp:first-child{--color-1:#572410;--color-2:#efa671;--color-3:#e5a173}.card-swiper__content .item-warp:nth-child(2){--color-1:#a7b8c0;--color-2:#dce6e9;--color-3:#3f4144}.card-swiper__content .item-warp:nth-child(3){--color-1:#dbb57e;--color-2:#f6e4c1;--color-3:#6f4a0c}.card-swiper__content .item-warp:nth-child(4){--color-1:#9acae8;--color-2:#c1eafc;--color-3:#0e3b5a}.card-swiper__content .item-warp:nth-child(5){--color-1:#a497e2;--color-2:#cfc7f6;--color-3:#392c6d}.card-swiper__content .item-warp:nth-child(6){--color-1:#f9909d;--color-2:#fecad2;--color-3:#da324b}.card-swiper__content .item-warp:nth-child(7){--color-1:#ffa083;--color-2:#f1b19f;--color-3:#ce492a}.card-swiper__content .item-warp:nth-child(8){--color-1:#22211f;--color-2:#454341;--color-3:#ffe4a8}.card-swiper__content .item{background-image:linear-gradient(to right,var(--color-1),var(--color-2));border:1px solid var(--color-2);border-radius:10px;color:var(--color-3);cursor:pointer;position:relative;width:100%}.card-swiper__thumb{position:relative}.card-swiper__thumb .thumb-line{height:1px;position:absolute;width:100%}.card-swiper__thumb .thumb .name{color:var(--text-color-60)}.card-swiper__thumb .thumb .circle{background-color:var(--text-color-60);border-radius:100%;height:4px;width:4px}.card-swiper__thumb .thumb.active .name{color:var(--main-text-color)}.card-swiper__thumb .thumb.active .circle{background-color:var(--main-text-color);position:relative;transform:scale(1.2)}.card-swiper__thumb .thumb.active .circle:after{background-color:var(--main-text-color);border-radius:10px;content:\"\";filter:blur(3px);height:8px;left:-1.5px;opacity:.3;position:absolute;top:-2px;width:8px}.card-swiper.is-mobile .card-swiper__content{padding-bottom:105px}.card-swiper.is-mobile .card-swiper__content .item-warp{margin:0;padding:0 10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
