<template>
  <div class="add-block-left mb-4">
    <Popover placement="right-end" popper-class="menu-add-popper" width="auto" :visible-arrow="false" trigger="click">
      <div class="menu-add-list rounded">
        <template v-for="(item,i) in model.getCanAddWidget()">
          <Popover v-if="item.children" :key="i + 'children'" v-model="childPopoverVisible" trigger="hover" popper-class="menu-add-popper" :visible-arrow="false" placement="right-end">
            <div v-for="(child, childIndex) in item.children" :key="'child' + childIndex" class="menu-add-item w-[180px] cursor-pointer rounded flex items-center" @click="addWidget(child)">
              {{ $t(child.title) }}
            </div>
            <div slot="reference">
              <div class="menu-add-item w-[180px] cursor-pointer rounded flex items-center justify-between">
                <div class="flex items-center">
                  <im-icon class="text-18" :icon="item.icon" />
                  <span class="ml-3">{{ $t(`model.${item.title}`) }}</span>
                </div>
                <i class="el-icon-caret-right ml-5" />
              </div>
            </div>
          </Popover>
          <div v-else-if="item.targetPages?.includes(model?.pageInfo?.name) || !item.targetPages" :key="i + 'child'" class="menu-add-item w-[180px] cursor-pointer rounded flex items-center" @click="addWidget(item)">
            <im-icon class="text-18" :icon="item.icon" />
            <span class="ml-3">{{ $t(`model.${item.title}`) }}</span>
          </div>
        </template>
      </div>
      <div slot="reference" class="add-block-content">
        <div class="btn flex items-center justify-center border border-lineColor-2 border-dashed rounded px-2 text-white hover:bg-fillColor-2 cursor-pointer">
          <icon-upload />
          <span class="ml-2 text-12">{{ $t('添加区块') }}</span>
        </div>
      </div>
    </Popover>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { Popover } from 'element-ui'
import iconUpload from '@/assets/svg/icon/iconUpload.svg'
import {insertReduceStep} from "~/utils/event";
import {scrollToWidget} from "~/site/core";

export default {
  components: {
    Popover,
    iconUpload
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      childPopoverVisible: false
    }
  },
  methods: {
    addWidget(item) {
      this.childPopoverVisible = false
      // eslint-disable-next-line new-cap
      const model = new item.model()
      const index = this.model.addWidgetLast(model)
      insertReduceStep(this, { element: cloneDeep(model), index })
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
      document.body.click()
      scrollToWidget(this, model, index)
    }
  }
}
</script>
<style lang="less" scoped>
.add-block-left {
  cursor: pointer;
  vertical-align: middle;
  line-height: 32px;
}
.add-block-content {
  .add-circle {
    border: 1px solid @primary-color;
    color: @primary-color;
  }
  &:hover {
    .add-circle {
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
}

</style>
