<template>
  <div class="card-swiper" :class="{ 'is-mobile': $store.getters.isMobile }">
    <div ref="$content" class="card-swiper__content overflow-hidden w-full pt-10">
      <div v-if="$store.getters.isDesktop" ref="$view" :style="viewStyle" class="flex w-fit transition-all duration-700">
        <card-item v-for="(item, index) in cardList" :key="index + 'child'" :item="item" :editing="editing" class="w-[520px]" @click.native="slideTo(index)" />
      </div>
      <div v-else ref="$swiper" class="swiper">
        <div class="swiper-wrapper">
          <card-item v-for="(item, index) in cardList" :key="index + 'child'" class="swiper-slide" :editing="editing" :item="item" />
        </div>
      </div>
    </div>
    <div class="card-swiper__thumb cards flex max-w-[520px] mx-auto">
      <div :style="lineStyle" class="thumb-line bottom-[18.5px]"/>
      <div v-for="(item, index) in cardList" :key="index + 'child'" :class="{ 'active': activeIndex === index }" class="h-[60px] flex-1 flex-shrink-0 thumb flex items-center flex-col cursor-pointer justify-center" @click="slideTo(index)">
        <div class="name text-[12px]">{{ item.name }}</div>
        <div class="circle mt-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination, Autoplay } from 'swiper'
import tinycolor from 'tinycolor2'
import 'swiper/swiper-bundle.min.css'
import CardItem from './item.vue'
import {off, on} from "~/utils/dom";
import {sleep} from "~/utils";

export default {
  name: 'VipUserInfoCardSwiper',
  components: {
    CardItem,
  },
  props: {
    cardList: {
      type: Array,
      default() {
        return []
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    userLevel: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      left: 0,
      swiper: null,
      list: [
        {
          status: 1,
          name: 'VIP1'
        },
        {
          status: 2,
          name: 'VIP2'
        },
        {
          status: 3,
          name: 'VIP3'
        },
        {
          status: 4,
          name: 'VIP4'
        },
        {
          status: 5,
          name: 'VIP5'
        },
        {
          status: 6,
          name: 'VIP6'
        },
        {
          status: 7,
          name: 'VIP7'
        },
        {
          status: 8,
          name: 'VIP8'
        }
      ],
      activeIndex: 0
    }
  },
  computed: {
    viewStyle() {
      return {
        transform: `translateX(${this.left}px)`
      }
    },
    lineStyle() {
      return {
        background: 'linear-gradient(270deg, var(--main-text-color) 0%, var(--text-color-10) 100%)'
      }
    }
  },
  watch: {
    async '$store.getters.isMobile'(newVal) {
      if (newVal) {
        await this.initSwiper()
      } else {
        this.left = 0
        await sleep(300)
        this.slideTo()
      }
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.removeResizeListener()
  },
  methods: {
    localCurrentLevelCard() {
      this.$nextTick(() => {
        const cardIndex = this.cardList.findIndex(item => item.level === this.userLevel)
        if (cardIndex > -1) {
          this.slideTo(cardIndex)
        }
      })
    },
    addResizeListener() {
      on(window, 'resize', this.handleResize)
    },
    removeResizeListener() {
      off(window, 'resize', this.handleResize)
    },
    handleResize() {
      this.slideTo()
    },
    async initSwiper() {
      if (!this.$store.getters.isMobile) return
      await sleep(300)
      Swiper.use([Navigation, Pagination, Autoplay])
      this.swiper?.destroy()
      this.swiper = new Swiper(this.$refs.$swiper, {
        direction: 'horizontal',
        spaceBetween: 16,
        speed: 800,
        modules: [Navigation, Pagination, Autoplay],
        on: {
          slideChangeTransitionEnd: (instance) => {
            if (this.$store.getters.isMobile) {
              this.activeIndex = instance.activeIndex
            }
          }
        }
      })
      await this.$nextTick()
      this.swiper?.slideTo(this.activeIndex)
    },
    getSlideLeft() {
      if (!this.cardList.length) return
      const $list = this.$refs.$content.querySelectorAll('.item')
      const $rule = document.querySelector('.card-swiper__thumb')
      const $current = $list[this.activeIndex]
      const itemLeft = $current?.offsetLeft
      const ruleLeft = $rule.offsetLeft
      return ruleLeft - itemLeft
    },
     slideTo(index) {
      const slideIndex = typeof index === 'number' ? index : this.activeIndex
      if (this.$store.getters.isDesktop) {
        this.activeIndex = slideIndex
        this.left = this.getSlideLeft()
      } else {
        this.activeIndex = slideIndex
        this.swiper?.slideTo(this.activeIndex)
      }
    },
    init() {
      this.initSwiper()
      this.addResizeListener()
      this.localCurrentLevelCard()
    }
  }
}
</script>

<style lang="less">
.card-swiper {
  .var-colors() {
    &:nth-child(1) {
      --color-1: #572410;
      --color-2: #efa671;
      --color-3: #e5a173;
    }

    &:nth-child(2) {
      --color-1: #a7b8c0;
      --color-2: #dce6e9;
      --color-3: #3f4144;
    }

    &:nth-child(3) {
      --color-1: #dbb57e;
      --color-2: #f6e4c1;
      --color-3: #6f4a0c;
    }

    &:nth-child(4) {
      --color-1: #9acae8;
      --color-2: #c1eafc;
      --color-3: #0e3b5a;
    }

    &:nth-child(5) {
      --color-1: #a497e2;
      --color-2: #cfc7f6;
      --color-3: #392c6d;
    }

    &:nth-child(6) {
      --color-1: #f9909d;
      --color-2: #fecad2;
      --color-3: #da324b;
    }

    &:nth-child(7) {
      --color-1: #ffa083;
      --color-2: #f1b19f;
      --color-3: #ce492a;
    }

    &:nth-child(8) {
      --color-1: #22211f;
      --color-2: #454341;
      --color-3: #ffe4a8;
    }
  }
  &__content {
    padding-bottom: 120px;
    .item-warp {
      margin: 0 30px;
      .var-colors ();
    }
    .item {
      width: 100%;
      border-radius: 10px;
      border: 1px solid var(--color-2);
      color: var(--color-3);
      background-image: linear-gradient(to right, var(--color-1), var(--color-2));
      position: relative;
      cursor: pointer;
    }
  }
  &__thumb {
    position: relative;

    .thumb-line {
      width: 100%;
      height: 1px;
      position: absolute;
    }

    .thumb {
      .name {
        color: var(--text-color-60);
      }

      .circle {
        width: 4px;
        height: 4px;
        background-color: var(--text-color-60);
        border-radius: 100%;
      }
    }

    .thumb {
      &.active {
        .name {
          color: var(--main-text-color);
        }
        .circle {
          position: relative;
          transform: scale(1.2);
          background-color: var(--main-text-color);
          &:after {
            content: '';
            border-radius: 10px;
            filter: blur(3px);
            height: 8px;
            opacity: .3;
            width: 8px;
            background-color: var(--main-text-color);
            position: absolute;
            top: -2px;
            left: -1.5px;
          }
        }
      }
    }
  }
  &.is-mobile {
    .card-swiper__content {
      padding-bottom: 105px;
      .item-warp {
        padding: 0 10px;
        margin: 0;
      }
    }
  }
}
</style>
