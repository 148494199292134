<template>
  <el-dialog :visible="$store.state.site.couponDialogVisible" width="420px" title="获得优惠券" append-to-body class="coupon-dialog" :show-close="false">
    <div class="coupon-dialog__content">
      <el-scrollbar class="list-area">
        <coupon-item v-for="item in $store.state.goods.loginCoupons" :key="item.id" :data="item" class="mb-4" />
      </el-scrollbar>
      <div class="pt-5">
        <div class="confirm-btn" @click="handleSubmit">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog, message} from 'element-ui'
import CouponItem from "~/site/components/couponPage/couponItem.vue";
export default {
  name: 'CouponDialog',
  components: {
    [Dialog.name]: Dialog,
    CouponItem
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    handleCancel() {
      this.$store.commit('site/SET_COUPON_DIALOG', false)
    },
    async handleSubmit() {
      const params = {
        player_coupons_ids: this.$store.state.goods.loginCoupons.map(item => item.player_coupons_id)
      }
      const [err] = await this.$utils.to(this.$api.activity.couponViewed(params))
      if (!err) {
        this.handleCancel()
      } else {
        message.error(err)
      }
    }
  }
}
</script>

<style scoped lang="less">
.coupon-dialog {
  .list-area {
    height: 300px;
  }
  .confirm-btn {
    width: 100px;
    height: 32px;
    background-color: @primary-1;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
