<template>
  <client-only>
    <transition name="el-fade-in-linear">
      <bg-style v-if="dialogVisible" class="dialog-widget" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" :bg="model.background">
        <div class="dialog-widget__content">
          <bg-style v-loading="loading" class="modal-wrapper" :bg="model.contentBackground" :radius="model.radius">
            <div v-if="!editing" class="dialog-widget__close z-10 cursor-pointer" @click="handleConfirm">
              <i class="el-icon-close" />
            </div>
            <div v-if="visible" id="dialog-swiper" ref="swiper" class="swiper">
              <div class="slide-block swiper-wrapper">
                <div v-for="(item, index) in model.list" :key="index" class="swiper-slide">
                  <rich-text v-if="model.titleVisible" v-model="item.title" class="title w-full swiper-no-swiping" :disabled="!editing" placement="bottom" :editing="editing" />
                  <rich-text v-if="model.subtitleVisible" v-model="item.subtitle" class="swiper-no-swiping mt-5" :disabled="!editing" :editing="editing" />
                  <div class="mt-4 text-center swiper-img relative">
                    <cover-block v-if="editing" class="cover-btn" title="设置图片" @click="handleSetImg(index)" />
                    <img class="inline-block" :src="item.background.backgroundImage" alt="" @click="handleNavTo(item)" />
                  </div>
                </div>
              </div>
              <div class="swiper-pagination relative" />
            </div>
            <div v-if="model.buttonVisible" class="text-center">
              <site-button class="min-w-[80px]" @click="handleConfirm">{{ model.buttonText }}</site-button>
            </div>
          </bg-style>
        </div>
      </bg-style>
    </transition>
  </client-only>
</template>

<script>
import { Navigation, Pagination, Swiper } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import debounce from "lodash.debounce";
import RichText from "~/components/richText/index.vue"
import CoverBlock from "~/components/common/CoverBlock.vue";
import SiteButton from "~/site/components/forms/button.vue";
import { navTo } from "~/utils";
import {DeviceEnum} from "~/enums/deviceEnum";

export default {
  name: 'DialogWidgetModel',
  components: {
    SiteButton,
    CoverBlock,
    RichText
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: DeviceEnum.DESKTOP
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      visible: true,
      swiper: {}
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    dialogVisible() {
      let result = false
      if (this.editing && this.$store.state.site.dialogVisible) {
        result = true
      } else if (!this.editing && this.$store.state.site.dialogVisible) {
        result = true
      }
      return result
    }
  },
  watch: {
    dialogVisible(n) {
      if (n) {
        this.refresh()
      }
    },
    device(n, o) {
      if (n !== o) {
        this.refresh()
      }
    }
  },
  mounted() {
    if (process.client) {
      this.model.__refresh = debounce(this.refresh, 1000)
      if (!this.editing && this.model.loadVisible) {
        this.$store.commit('site/SET_DIALOG_VISIBLE', true)
        // this.init()
      }
    }
  },
  methods: {
    handleNavTo(item) {
      if (this.editing) return
      navTo(this, item)
      this.handleConfirm()
    },
    handleConfirm() {
      if (this.editing) return
      this.$store.commit('site/SET_DIALOG_VISIBLE', false)
    },
    init() {
      this.$nextTick(() => {
        this.refresh()
      })
    },
    refresh() {
      this.loading = true
      this.visible = false
      this.$nextTick(() => {
        this.visible = true
      })
      const timer = setTimeout(() => {
        this.initSwiper()
        clearTimeout(timer)
      }, 1000)
    },
    initSwiper() {
      Swiper.use([Navigation, Pagination])
      const that = this
      this.swiper = new Swiper(this.$refs.swiper, {
        preventClicks: false,
        loop: !this.editing && this.model.list.length > 1,
        updateOnWindowResize: false,
        resistanceRatio: 0,
        direction: 'horizontal',
        modules: [Navigation, Pagination],
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        autoplay: false,
        on: {
          init() {
            that.loading = false
          },
          slideChangeTransitionEnd() {
            //
          }
        }
      })
    },
    handleSetImg(index) {
      this.SiteMenu.showImageWithKey(`dialog-image-${index}`)
    }
  }
}
</script>

<style lang="less">
.dialog-widget {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3001;
  &.is-mobile {
    max-width: 100%;
    .modal-wrapper {
      width: 100%;
    }
    .dialog-widget__content {
      width: 100%;
    }
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__content {
    position: absolute;
    margin: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3021;
    width: fit-content;
    height: fit-content;
    .title {
      font-size: 24px;
    }
    .ql-size-large {
      font-size: 32px;
    }
    .modal-wrapper {
      background-color: unset;
      width: 720px;
      padding: 20px;
      overflow: hidden;
      position: relative;
      .swiper-slide {
        padding: 20px;
        .swiper-img {
          img {
            max-height: 360px;
          }
        }
        .cover-btn {
          position: absolute !important;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
