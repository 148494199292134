<template>
  <div class="recharge-activity">
    <div class="recharge-activity__logo">
      <bg-style v-if="editing || !editing && model.activityLogo.backgroundImage" class="w-[240px] h-[80px] mb-5" :bg="model.activityLogo">
        <CoverBlock
          v-if="editing"
          :title="$t('edit.setImage')"
          class="logo_cover_block"
          @click="handleOpenLogoUpload"
        />
      </bg-style>
      <rich-text v-if="model.titleVisible" v-model="model.title" :stroke.sync="model.titleStroke" class="mb-5 recharge-activity__title" :editing="editing" :disabled="!editing" theme="snow" />
      <div v-if="model.quoteVisible" class="text-16 px-4 w-[300px] recharge-activity__tip mb-5">
        <span>{{ $t('siteBuild.activity.rechargeTip') }}</span>
        <span class="ml-2">{{ currencyLabel }} <span class="text-danger">{{ used }}</span><span>/{{ currentValue }}</span></span>
      </div>
    </div>
    <rich-text v-if="editing || (!editing && getTextFromHtmlStr(model.activityTitle))" v-model="model.activityTitle" class="recharge-activity__mobile-title" :stroke.sync="model.titleStroke" :disabled="!editing" :editing="editing" theme="snow" />
  </div>
</template>

<script>
import moment from "moment/moment"
import {mapState} from "vuex";
import cloneDeep from "lodash.clonedeep";
import RichText from "~/components/richText/index.vue"
import Bus from "~/site/model/bus"
import {isBuildWebSite} from "~/config";
import {getTextFromHtmlStr} from "~/utils/htmlStringHandler";

const isSandBox = !isBuildWebSite

export default {
  name: 'RechargeActivity',
  components: {RichText},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    currencyCode: {
      type: String,
      default: ''
    },
    currencyLabel: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: 0,
      used: 0,
      activityRes: {
        activity_rule: {
          quota: 0
        }
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo
    })
  },
  beforeDestroy() {
    Bus.$off('reloadActivity')
  },
  async mounted() {
    this.init()
    await this.getActivityData()
    await this.getActivityCurrentValue()
  },
  methods: {
    getTextFromHtmlStr,
    init() {
      Bus.$on('reloadActivity', async () => {
        await this.getActivityData()
        await this.getActivityCurrentValue()
      })
    },
    handleOpenLogoUpload() {
      this.SiteMenu.showImageWithKey('recharge-activity-logo')
    },
    async getActivityCurrentValue() {
      const project_id = this.userInfo && this.userInfo.project_id
      if (!project_id) return
      const params = {
        sandbox: Number(isSandBox),
        currency: this.currencyCode,
        project_id,
        player_id: (this.userInfo?.uid || '').trim(),
        activity_id: this.activityRes.biz_id,
        activity_type: 6
      }
      const [err, res] = await this.$utils.to(this.$api.activity.getRechargeCurrentValue(params))
      if (!err) {
        this.$store.commit('project/SET_RECHARGE_STATIC', res)
        this.currentValue = res.quota
        this.used = res.used
      }
    },
    async getActivityData() {
      const now_stem = Math.floor(moment().utc().valueOf() / 1000)
      const [err, res] = await this.$utils.to(this.$api.activity.getActivityList({ now_time: now_stem, player_id: (this.userInfo?.uid || '').trim(), project_id: this.$store.state.project?.projectId, activity_type: 6, status: 2}, {editing: this.editing}))
      if (!err && res?.list) {
        const data = cloneDeep(res?.list?.[0] || {})
        data.activity_rule = JSON.parse(data.activity_rule)
        this.activityRes = data
        this.$store.commit('project/SET_RECHARGE_ACTIVITY', data.activity_rule)
      }
    }
  }
}
</script>

<style lang="less">
.recharge-activity {
  display: flex;
  align-items: center;
  &__logo {
    margin-right: 40px;
  }
  &__tip {
    padding: 2px 11px;
    background-color: #ceeaee;
    border-radius: 2px;
    color: #292e42;
    width: fit-content;
  }
  &__title {
    .rich-text.focus-editor .ql-toolbar.ql-snow {
      flex-wrap: wrap;
    }
    .rich-text .ql-toolbar.ql-snow {
      width: 300px;
    }
  }
  &__mobile-title {
    .rich-text.focus-editor .ql-toolbar.ql-snow {
      flex-wrap: wrap;
      min-width: 200px;
    }
    &.quill-wrap.top .ql-toolbar.ql-snow {
      left: 110px;
    }
  }
  &.is-mobile {
    flex-wrap: wrap;
    padding: 0 12px;
    .recharge-activity__logo {
      margin-right: 0;
    }
  }
}
</style>
