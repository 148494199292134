<template>
  <div class="edit-nav-wrap" :class="{ 'is-bg': showBg }">
    <i v-if="showRemove" class="el-icon-remove cursor-pointer z-10" @click="handleRemove" />
    <menu-item v-if="showName" class="pt-0" label="名称">
      <base-input v-model="target[nameKey]" :keys="keys" :model-key="targetKey" :target-index="targetIndex" target-key="label" size="small" />
    </menu-item>
    <menu-item v-if="target?.icon" label="ICON">
      <menu-background-image :target="target.icon" image-size-key="" />
    </menu-item>
    <menu-item :label="label">
      <el-radio-group v-model="target.navType" class="mt-2">
        <el-radio class="w-full" label="1">
          <span class="nav-label text-white font-normal text-12">外部链接</span>
          <div v-if="target.navType === NavTypeEnum.URL" class="w-full mt-2">
            <base-input
              v-model="target.url"
              class="w-full"
              clearable
              size="small"
              placeholder="https://example.com"
            />
          </div>
        </el-radio>
        <el-radio class="mt-4 w-full" label="2">
          <span class="nav-label text-white text-12 font-normal">站内页面</span>
          <div v-if="target.navType === NavTypeEnum.PAGE" class="w-full mt-2">
            <im-select
              v-model="target.page"
              class="w-full"
              label-key="name"
              :options="SitePage.list"
              placeholder="站内页面"
              clearable
              size="small"
              @select="handleSelectUrl"
            />
          </div>
        </el-radio>
        <el-radio class="mt-4 w-full" label="3">
          <span class="nav-label text-white text-12 font-normal">模块</span>
          <div v-if="target.navType === NavTypeEnum.MODEL"  class="w-full mt-2">
            <im-select
              v-model="target.model"
              class="w-full"
              :options="modelOptions"
              size="small"
              clearable
              placeholder="模块"
              @change="handleChangeModel"
            />
          </div>
        </el-radio>
      </el-radio-group>
    </menu-item>
  </div>
</template>

<script>
import { RadioGroup, Radio } from 'element-ui'
import menuItem from "~/components/menu/menuItem";
import BaseInput from "~/components/base/baseInput"
import ImSelect from "~/components/common/ImSelect";
import {NavTypeEnum} from "~/enums/siteEnum";
import {FooterWidgetModel, HeaderWidgetModel} from "~/site/model";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";

export default {
  name: 'NavEdit',
  components: {
    MenuBackgroundImage,
    menuItem,
    BaseInput,
    ImSelect,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  props: {
    showBg: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    showName: {
      type: Boolean,
      default: false
    },
    nameKey: {
      type: String,
      default: 'label'
    },
    label: {
      type: String,
      default: '跳转事件'
    },
    keys: {
      type: Array,
      default() {
        return []
      }
    },
    targetIndex: {
      type: [Number, String],
      default: ''
    },
    targetKey: {
      type: String,
      default: ''
    },
    target: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      name: '',
      type: '1',
      url: ''
    }
  },
  computed: {
    NavTypeEnum() {
      return NavTypeEnum
    },
    modelOptions() {
      const site = this.site
      const widgetList = site.widgetList || []
      const indexObj = {}
      const ignores = [HeaderWidgetModel.name, FooterWidgetModel.name]
      const widgets = widgetList.filter(item => !ignores.includes(item.name))
      const options = widgets.map(item => {
        const label = item.moduleName
        if (indexObj[label]) indexObj[label] = indexObj[label] + 1
        else indexObj[label] = 1
        const isSame = widgets.filter(tag => tag.moduleName === item.moduleName).length > 1
        return {
          label: isSame ? `${label}-${indexObj[label]}` : label,
          value: item.id
        }
      }).filter(item => item.label)
      options.push({
        label: site?.dialogSetting?.moduleName,
        value: site?.dialogSetting?.id
      })
      return options
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // const { current, list } = this.SitePage
      // const indexObj = {}
      // const widgetList = this.widgetList || []
      // const ignores = [HeaderWidgetModel.name, FooterWidgetModel.name]
      // const widgets = widgetList.filter(item => !ignores.includes(item.name))
      // this.modelOptions = widgets.map(item => {
      //   const label = item.moduleName
      //   if (indexObj[label]) indexObj[label] = indexObj[label] + 1
      //   else indexObj[label] = 1
      //   const isSame = widgets.filter(tag => tag.moduleName === item.moduleName).length > 1
      //   return {
      //     label: isSame ? `${label}-${indexObj[label]}` : label,
      //     value: item.id
      //   }
      // }).filter(item => item.label)
      // this.modelOptions.push({
      //   label: site?.dialogSetting?.moduleName,
      //   value: site?.dialogSetting?.id
      // })
    },
    close() {
      this.SiteMenu.close('navediter')
    },
    handleRemove() {
      this.$emit('remove')
    },
    handleSelectUrl() {
      //
    },
    handleChangeModel() {
      //
    }
  }
}
</script>
<style lang="less">
.edit-nav-wrap {
  position: relative;
  &.is-bg {
    background-color: @fill-color-2;
    border-radius: @border-radius-base;
    padding: 12px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background-color: @primary-1;
    border-color: @primary-1;
  }
  .el-icon-remove {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
