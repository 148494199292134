<template>
<!--  不可隐藏最外层div，否则用户自定义代码样式会失效 -->
  <div ref="wrapper" class="editor-widget-wrap relative flex items-center justify-center" :class="getClass">
<!--    <xsolla v-if="editing" />-->
    <m-editor v-if="editing" v-model="model.value" @input="onChangeValue" />
  </div>
</template>

<script>
import DOMPurify from 'isomorphic-dompurify';
import { EditorCodeBlockWidgetModel } from '../../model/index'
import MEditor from './../../components/editor/index.vue'
import { elementTags } from '~/plugins/element-ui.js'
import { xss } from '~/config'
// import Xsolla from "~/components/xsolla";
export default {
  name: 'EditorCodeBlockWidget',
  components: {
    MEditor,
    // Xsolla
  },
  props: {
    model: {
      type: Object,
      default() {
        return EditorCodeBlockWidgetModel
      }
    },
    editing: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: '',
      data: '',
      count: 0,
      myComponents: elementTags,
      componentList: [],
      config: xss,
      code: ''
    }
  },
  computed: {
    getClass() {
      const classList = []
      if (this.editing) {
        classList.push('p-24')
        classList.push('p-5')
      }
      return classList
    }
  },
  created() {
    //
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.editing) {
        this.insertHtml()
        this.insertScript()
      }
    },
    insertHtml() {
      if(!this.model.value){
        return
      }
      const html = DOMPurify.sanitize(`<div>${this.model.value}</div>`, this.config)
      const $div = document.createElement('div')
      $div.innerHTML = html
      this.$refs.wrapper.append($div)
    },
    insertScript() {
       if (!this.model.value) {
         return
       }
      const value = String(this.model.value)
      const scripts = value.match((/<script((.|\n)*?)<\/script>/gi))
      const fragment = document.createDocumentFragment()
      if (!scripts) return
      for (let i = 0; i < scripts.length; i++) {
        const con = document.createElement('script')
        const current = scripts[i]
        const start = current.indexOf('t>')
        const end = current.indexOf('</s')
        con.innerHTML = current.substr(start + 2, end - 8)
        fragment.append(con)
      }
      this.$refs.wrapper.append(fragment)
    },
    onChangeValue(data) {
      //
    }
  }
}
</script>

<style lang="less">
.@{mobile-cls} {
  .editor-widget-wrap {
    padding: 0 16px;
    width: 100%;
  }
}
</style>
