<template>
  <el-dialog :visible.sync="visible" class="point-dialog" :title="$t('siteBuild.points')" :width="$store.getters.isMobile ? '100%' : '600px'" append-to-body>
    <div class="point-dialog__body mt-5 pb-4">
      <div class="px-4">{{ $t('siteBuild.currentPoint') }}：<span class="text-[24px] font-bold">{{ $store.state.user.vipUserInfo.points }}</span></div>
      <el-tabs v-model="tab" class="px-4 mt-4" @tab-click="queryList">
        <el-tab-pane key="0" :label="$t('siteBuild.allType')"></el-tab-pane>
        <el-tab-pane key="1" :label="$t('siteBuild.income')"></el-tab-pane>
        <el-tab-pane key="2" :label="$t('siteBuild.outlay')"></el-tab-pane>
      </el-tabs>
      <el-scrollbar v-loading="loading" class="list h-[300px] overflow-auto px-4">
        <div v-for="(item, index) in list" :key="'child' + index" class="item mb-5 flex items-center justify-between">
          <div class="left-name">
            <div>{{ $t(item.type_name) }}</div>
            <div>{{ item.create_time }}</div>
          </div>
          <div class="value">
            <i v-if="item.amount > 0" class="el-icon-plus font-bold" /> {{ item.amount }}
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Tabs, TabPane } from 'element-ui'
import {formatZoneTime} from "~/utils";
import {VipStatusTypeNameMap} from "~/enums/data";
export default {
  name: 'VipUserInfoPointDialog',
  components: {
    [Dialog.name]: Dialog,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  data() {
    return {
      visible: false,
      list: [],
      loading: false,
      tab: ''
    }
  },
  created() {
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.queryList()
      })
    },
    async queryList() {
      const params = {
        in_or_out: Number(this.tab),
        page_size: 9999
      }
      this.loading = true
      const [err, res] = await this.$utils.to(this.$api.vip.getPointLog(params))
      this.loading = false
      if (!err) {
        this.list = (res.data || []).map(item => {
          const newItem = {...item}
          newItem.create_time = formatZoneTime(newItem.create_time * 1000).text
          newItem.type_name = VipStatusTypeNameMap[item.type_name]
          return newItem
        })
      }
    }
  }
}
</script>

<style lang="less">
.point-dialog {
  .el-dialog__body {
    padding: 0;
  }
  &__body {
    color: black;
  }
}
</style>
