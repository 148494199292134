// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vip-user__info{font-size:14px}.vip-user__info .email{color:var(--main-text-color)}.vip-user__info .other{color:var(--text-color-60)}.vip-user__info .el-icon-user{color:var(--main-text-color);font-size:60px}.vip-user__rule{background-color:hsla(0,0%,100%,.059);border-radius:4px}.vip-user.is-mobile .vip-user__swiper{padding:0 16px}.vip-user.is-mobile .vip-user__info{font-size:12px}.vip-user.is-mobile .vip-user__info .el-icon-user{font-size:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
