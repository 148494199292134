<template>
  <bg-style class="vip-user py-10" :class="{ 'is-mobile': $store.getters.isMobile }" :bg="model.background" :style="getStyle">
    <div class="vip-user__info relative max-w-[1280px] mx-auto pt-10 text-white">
      <div class="flex items-center mx-auto w-fit text-textColor-1">
        <i class="el-icon-user" />
        <div class="ml-4">
          <div class="flex items-center email">
            <span>{{ $t('siteBuild.email') }}：</span>
            <span>{{ $store.state.user?.siteLoginUserInfo?.email }}</span>
          </div>
          <div class="mt-1 other">
            <div class="cursor-pointer flex items-center" @click.stop="handleGrowthValueClick">
              <span>{{ $t('siteBuild.growth') }}：</span>
              <span>{{ $store.state.user.vipUserInfo.growth }}</span>
              <i class="el-icon-arrow-right" />
            </div>
            <div class="cursor-pointer flex items-center" @click.stop="handlePointsClick">
              <span>{{ $t('siteBuild.points') }}：</span>
              <span>{{ $store.state.user.vipUserInfo.points }}</span>
              <i class="el-icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cardList.length" class="vip-user__swiper mt-5 text-white">
      <card-swiper ref="$card" :card-list="cardList" :user-level="userLevel" :editing="editing" />
    </div>
    <div class="px-4">
      <div v-if="model.showRemark" class="max-w-[520px] mx-auto text-[14px] vip-user__rule mt-2 p-5 relative">
        <rich-text v-model="model.ruleTitle" :disabled="!editing" :editing="editing" :exclude="['font-size']" :class="$store.getters.isDesktop ? 'text-[22px]' : 'text-[20px]'" />
        <rich-text v-model="model.ruleText" :disabled="!editing" :editing="editing" class="mt-8" />
      </div>
    </div>
    <point-dialog ref="$point" />
    <growth-dialog ref="$growth" />
  </bg-style>
</template>

<script>
import {Message} from "element-ui";
import CardSwiper from './components/cardSwiper.vue'
import GrowthDialog from './components/growthDialog.vue'
import PointDialog from './components/pointDialog.vue'
import Bus from "~/site/model/bus";
import {DeviceEnum} from '~/enums/deviceEnum';
import RichText from "~/components/richText/index.vue";
import {BusEventMap} from "~/enums/data";
import {getTextColor} from "~/utils";
import tinycolor from "tinycolor2";

export default {
  name: 'VipUserInfoWidget',
  components: {
    RichText,
    CardSwiper,
    PointDialog,
    GrowthDialog
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: DeviceEnum.DESKTOP,
    }
  },
  data() {
    return {
      cardList: []
    }
  },
  computed: {
    isVipLogin() {
      return this.$store.state.user?.siteLoginUserInfo?.email
    },
    userLevel() {
      return this.$store.state.user.vipUserInfo?.level || 1
    },
    getStyle() {
      const mainTextColor = getTextColor(this.model.textColor)?.color
      const color = tinycolor(mainTextColor)
      return {
        '--main-text-color': mainTextColor,
        '--text-color-60': color.setAlpha(0.6).toRgbString(),
        '--text-color-10': color.setAlpha(0.1).toRgbString(),
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    handleGrowthValueClick() {
      if (!this.isVipLogin) {
        return
      }
      this.$refs.$growth.init()
    },
    handlePointsClick() {
      if (!this.isVipLogin) {
        return
      }
      this.$refs.$point.init()
    },
    async init() {
      await this.getUserVipInfo()
      await this.getCardList()
      Bus.$on('reloadActivity', (type) => {
        if (type === BusEventMap.Payment) {
          this.getUserVipInfo()
        }
      })
      Bus.$on('reloadGood', (type) => {
        if ([BusEventMap.LoginOut].includes(type)) {
          this.$store.commit('user/SET_VIP_USER_INFO', {
            growth: 0,
            points: 0
          })
        } else if (type === BusEventMap.Login) {
          this.getUserVipInfo()
        }
      })
    },
    async getUserVipInfo() {
      if (this.editing || !this.$store.state.user.siteUserInfo) {
        //
      } else {
        await this.$store.dispatch('user/getUserVipInfo')
      }
    },
    async getCardList() {
      const [err, res] = await this.$utils.to(this.$api.vip.getVipRankList({ merchant_id: this.$store.state.project.merchantId }))
      if (!err) {
        const data = res.data ? res.data : (this.editing ? this.model.__mockData : [])
        this.cardList = data.map((item, index) => {
          const newItem = {...item}
          const nextItem = data[index + 1]
          newItem.nextLevelGrowth = nextItem ? nextItem.growth : 0
          return newItem
        })
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.vip-user {
  &__info {
    .email {
      color: var(--main-text-color);
    }
    .other {
      color: var(--text-color-60);
    }
    .el-icon-user {
      font-size: 60px;
      color: var(--main-text-color);
    }
    font-size: 14px;
  }
  &__rule {
    background-color: #ffffff0f;
    border-radius: 4px;
  }
  &.is-mobile {
    .vip-user__swiper {
      padding: 0 16px;
    }
    .vip-user__info {
      font-size: 12px;
      .el-icon-user {
        font-size: 30px;
      }
    }
  }
}
</style>
