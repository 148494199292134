<template>
  <div class="vip-user-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
      <menu-background-image class="mt-2" :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.showRemark" label="显示说明" target-key="showRemark" />
    </menu-item>
    <menu-item label="文字颜色">
      <menu-fill :target="model.textColor" target-key="textColor" />
    </menu-item>
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";

export default {
  name: 'VipUserInfoWidgetEdit',
  components: {MenuSwitch, MenuBackgroundImage, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  created() {
    //
  },
  methods: {

  }
}
</script>

<style scoped lang="less">

</style>
