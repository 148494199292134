<template>
  <component :is="isMobile ? 'el-drawer' : 'el-dialog'" :visible.sync="visible" v-bind="options" class="sign-info">
    <div class="sign-info__content" :style="customStyle">
      <i class="el-icon-close sign-info__close" @click="handleClose" />
      <div class="title">{{ $t('siteBuild.reward') }}：</div>
      <div class="sign-info__detail">
        <div v-for="(item, index) in data.gift_list" :key="index" class="gift flex items-center justify-between">
          <div class="flex items-center">
            <div class="w-[40px]">
              <img class="max-w-[40px] max-h-[40px]" :src="item.gift_goods_logo" alt="" />
            </div>
            <span class="ml-2">{{ getLangIdI18n({ lang_id: item.gift_goods_name }, $store.state.locale.langIdData) }}</span>
          </div>
          <div class="flex items-center">
            <i class="el-icon-close mr-2" />
            <span>{{ item.num }}</span>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { Dialog, Drawer } from 'element-ui'
import {getLangIdI18n} from "~/utils";
export default {
  name: 'SignInfo',
  components: {
    [Dialog.name]: Dialog,
    [Drawer.name]: Drawer
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    customStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    options() {
      const dialogOptions = {
        width: '530px',
        top: '20vh'
      }
      const drawerOptions = {
        direction: 'btt',
        showClose: false,
        size: 'auto'
      }
      return this.isMobile ? drawerOptions : dialogOptions
    }
  },
  methods: {
    getLangIdI18n,
    init() {
      this.visible = true
    },
    handleClose() {
      this.visible = false
    }
  }
}
</script>


<style lang="less">
.sign-info {
  .el-drawer__header {
    display: none;
  }
  .el-drawer__body {
    padding: 48px 20px 68px 20px;
  }
  .el-dialog {
    border-radius: 4px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 52px 50px;
  }
  &__close {
    color: black;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    &:hover {
      color: @danger-color;
    }
  }
  &__detail {
    background-color: #f5f5f5;
    border-radius: 2px;
    margin-top: 12px;
    padding: 20px;
    color: black;
    max-height: 260px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background-color: #999;
    }
    .gift {
      margin-top: 15px;
      color: var(--sign-text-color-50);
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  &__content {
    .title {
      font-size: 20px;
      color: var(--sign-text-color);
    }
  }
}
</style>
