<template>
  <bg-style :bg="model.goodsBackground" class="vip-point-pc__item">
    <div class="thumb">
      <bg-style class="relative h-full" :bg="{...item.thumbBg, backgroundSize: model.iconFillType}" />
      <div v-if="stopCountdownTimer" class="off-sale">
        <i class="el-icon-time mr-1" />{{ $t('siteBuild.stopCountdown') }}：<span>{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
      </div>
    </div>
    <div class="info relative">
      <div class="name text-left">{{ item.name }}</div>
      <div v-if="item.is_purchase_limit" class="limit">
        Limit {{ item.has_purchase_num }}/{{ item.limits }}
      </div>
      <div v-if="isShowCountdown" class="pre-btn mt-4 relative">
        <div class="flex items-center">
          <i class="el-icon-time text-14 mr-1" />
          <template v-if="countdownDays === '00' && countdownHours !== '00'">
            <span>{{ countdownHours }}h:{{ countdownMinutes }}m</span>
          </template>
          <template v-if="countdownDays !== '00'">
            <span>{{ countdownDays }}d:{{ countdownHours }}h</span>
          </template>
          <template v-if="countdownDays === '00' && countdownHours === '00'">
            <span>{{ countdownMinutes }}m:{{ countdownSeconds }}s</span>
          </template>
        </div>
      </div>
      <div v-else class="buy flex items-center justify-center cursor-pointer mt-4" @click="handleBuy(item)">
        <img class="icon" :src="$store.state.project.vipConfig?.point_icon" alt="">
        <span>{{ item.price }}</span>
      </div>
    </div>
  </bg-style>
</template>

<script>
import pointGoods from '~/mixins/pointGoods'
export default {
  name: 'VipPointPcListItem',
  mixins: [pointGoods],
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="less">
.vip-point-pc__item {
  position: relative;
  overflow: hidden;
  border-radius: var(--goods-border-radius);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  .thumb {
    width: 100%;
    height: 188px;
    position: relative;
    .color-bg {
      border-radius: var(--goods-border-radius) var(--goods-border-radius);
    }
    .off-sale {
      position: absolute;
      bottom: 12px;
      right: 12px;
      background-color: rgba(0, 0, 0, 0.8);
      color: var(--second-text-color);
      border-radius: 30px;
      padding: 2px 12px;
      font-size: 12px;
    }
  }
  .info {
    padding: 16px;
    .name {
      font-size: 18px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 10px;
      color: var(--main-text-color);
    }
    .limit {
      font-size: 12px;
      margin-bottom: 10px;
      color: var(--main-text-color);
      border: 1px solid var(--main-text-color);
      padding: 2px 4px;
      width: fit-content;
      border-radius: var(--goods-border-radius);
    }
    .pre-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      line-height: 40px;
      font-size: 14px;
      border-radius: var(--goods-border-radius);
    }
    .buy {
      font-size: 14px;
      height: 40px;
      padding: 0 8px;
      text-align: center;
      background-color: var(--button-bg-color);
      color: var(--button-text-color);
      border-radius: var(--goods-border-radius);
      .icon {
        height: 26px;
        margin-right: 4px;
        width: 26px;
      }
    }
  }
}
</style>
