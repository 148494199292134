// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vip-user__swiper-item{height:260px}.vip-user__swiper-item .content{padding:12px}.vip-user__swiper-item .vip-icon{top:-40px;width:140px}.vip-user__swiper-item .title{font-size:40px;font-weight:700;line-height:1}.vip-user__swiper-item .title .current-level{font-size:20px;font-weight:300}.vip-user__swiper-item .progress{margin-top:10px;width:350px}.vip-user__swiper-item .current-growth{font-size:22px}.vip-user__swiper-item .rights-block{border-radius:0 0 8px 8px;bottom:-116px;height:202px;left:-20px;overflow:hidden;position:absolute;width:560px}.vip-user__swiper-item .rights-block .right-area{padding-top:80px}.vip-user__swiper-item .rights-block .right-area .right-item{border-bottom:1px solid var(--text-color-60);color:var(--main-text-color);font-size:16px;padding-bottom:12px}.vip-user__swiper-item .rights-block .bg{-webkit-backdrop-filter:blur(20px);backdrop-filter:blur(20px);height:260px;left:0;position:absolute;top:0;width:100%}.vip-user__swiper-item.is-mobile{height:190px}.vip-user__swiper-item.is-mobile .progress{font-size:12px;margin-top:8px;width:100%}.vip-user__swiper-item.is-mobile .current-growth{font-size:12px}.vip-user__swiper-item.is-mobile .rights-block{bottom:-106px;height:160px;left:-10px;width:calc(100% + 20px)}.vip-user__swiper-item.is-mobile .rights-block .right-item{font-size:12px}.vip-user__swiper-item.is-mobile .rights-block .right-area{padding:40px}.vip-user__swiper-item.is-mobile .content{padding:16px}.vip-user__swiper-item.is-mobile .title{font-size:30px}.vip-user__swiper-item.is-mobile .vip-icon{height:96px;top:-30px;width:96px}.vip-user__swiper-item.is-mobile .current-level{font-size:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
