// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vip-point-pc__item{border-radius:var(--goods-border-radius);box-shadow:0 1px 2px 0 rgba(0,0,0,.05);overflow:hidden;position:relative}.vip-point-pc__item .thumb{height:188px;position:relative;width:100%}.vip-point-pc__item .thumb .color-bg{border-radius:var(--goods-border-radius) var(--goods-border-radius)}.vip-point-pc__item .thumb .off-sale{background-color:rgba(0,0,0,.8);border-radius:30px;bottom:12px;color:var(--second-text-color);font-size:12px;padding:2px 12px;position:absolute;right:12px}.vip-point-pc__item .info{padding:16px}.vip-point-pc__item .info .name{color:var(--main-text-color);font-size:18px;height:20px;line-height:20px;margin-bottom:10px}.vip-point-pc__item .info .limit{border:1px solid var(--main-text-color);border-radius:var(--goods-border-radius);color:var(--main-text-color);font-size:12px;margin-bottom:10px;padding:2px 4px;width:-moz-fit-content;width:fit-content}.vip-point-pc__item .info .pre-btn{align-items:center;background-color:rgba(0,0,0,.3);color:#fff;display:flex;justify-content:center;line-height:40px}.vip-point-pc__item .info .buy,.vip-point-pc__item .info .pre-btn{border-radius:var(--goods-border-radius);font-size:14px;height:40px;text-align:center}.vip-point-pc__item .info .buy{background-color:var(--button-bg-color);color:var(--button-text-color);padding:0 8px}.vip-point-pc__item .info .buy .icon{height:26px;margin-right:4px;width:26px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
