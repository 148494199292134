<template>
  <bg-style class="point-store py-10" :bg="model.background" :style="getStyle">
    <div class="point-store__content relative">
      <rich-text v-if="model.titleVisible" v-model="model.title"  :disabled="!editing" :editing="editing" :exclude="['font-size']" :class="$store.getters.isDesktop ? 'text-[40px]' : 'text-[22px]'" />
      <div class="text-center relative mt-4 text-[18px] flex items-center justify-center current-level">
        <span>{{ $t('siteBuild.vipUserInfoWidget.currentPoint') }}：</span>
        <img class="w-[26px] h-[26px] mr-2" :src="$store.state.project.vipConfig?.point_icon" alt="">
        <span>{{ $store.state.user.vipUserInfo.points }}</span>
      </div>
      <pc-list v-if="$store.getters.isDesktop" :list="list" :model="model" :editing="editing" @exchange-success="getVipPointGoods" />
      <two-column-list v-else-if="$store.getters.isMobile && model.mobileLayoutType === StoreWidgetV2FlexLayoutEnum.TWO_COLUMN" :list="list" :model="model" :editing="editing" @exchange-success="getVipPointGoods" />
    </div>
    <tip-dialog ref="$tip" />
  </bg-style>
</template>

<script>
import { Message } from 'element-ui'
import TipDialog from "./components/tipDialog/index.vue";
import TwoColumnList from './components/twoColumnList/index.vue'
import PcList from './components/pcList/index.vue'
import RichText from "~/components/richText/index.vue";
import {generateDefaultBackground} from "~/site/model/helper";
import {getLangIdI18n, getTextColor} from "~/utils";
import {ForeverTimeValue} from "~/enums/date";
import {StoreWidgetV2FlexLayoutEnum} from "~/enums/layoutEnum";

export default {
  name: 'VipPointsStoreWidget',
  components: {
    RichText,
    PcList,
    TipDialog,
    TwoColumnList
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    }
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    StoreWidgetV2FlexLayoutEnum() {
      return StoreWidgetV2FlexLayoutEnum
    },
    getStyle() {
      return {
        '--main-text-color': getTextColor(this.model.mainTextColor).color,
        '--second-text-color': getTextColor(this.model.secondaryTextColor).color,
        '--button-bg-color': getTextColor(this.model.buttonBackground).color,
        '--button-text-color': getTextColor(this.model.buttonColor).color,
        '--goods-bg-color': getTextColor(this.model.goodsBackground).color,
        '--goods-border-radius': this.model.radius + 'px'
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getVipPointGoods()
      this.$store.dispatch('project/getVipConfigInfo')
    },
    async getVipPointGoods() {
      const [err, res] = await this.$utils.to(this.$api.vip.getVipPointGoods({ project_id: this.$store.state.project.projectId }))
      if (!err) {
        this.list = (res.data || []).map(item => {
          const newItem = {...item}
          newItem.thumbBg = generateDefaultBackground({ backgroundImage: newItem.icon.replace(/\s/g,'%20') })
          newItem.stop_time = newItem.stop_time === ForeverTimeValue ? null : newItem.stop_time
          newItem.name = getLangIdI18n({ lang_id: item.name }, this.$store.state.locale.langIdData)
          return newItem
        })
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.point-store {
  position: relative;
  &__content {
    max-width: 1280px;
    margin: 0 auto;
    .current-level {
      color: var(--main-text-color);
    }
  }
}
</style>
