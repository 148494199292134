<template>
  <div class="preview-wrap" :class="{[deviceClass]: 1}" :style="style">
    <portal-target name="destination" />
    <nuxt class="h-full" @change-style="changeStyle" />
    <coupon-dialog />
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { useGlobSetting } from '~/hooks/useGlobSetting'
import {off, on} from "~/utils/dom"
import {DefaultDevice, DefaultMobileDeviceWidth, DeviceClassMapping} from "~/settings/deviceSetting"
import {DeviceEnum} from "~/enums/deviceEnum"
import CouponDialog from "~/site/components/couponDialog.vue"

export default {
  name: 'DefaultLayout',
  components:{
    CouponDialog
  },
  data() {
    const { prefixCls }  = useGlobSetting()
    return {
      prefixCls: prefixCls + '-default',
      deviceClass: DeviceClassMapping[DefaultDevice],
      device: 'desktop',
      style:{}
    }
  },
  mounted() {
    this.__$body = document.body
    this.resizeMethod()
    this.throttleLazyResizeMethod = throttle(this.resizeMethod, 500)
    on(window, 'resize',this.throttleLazyResizeMethod)
  },
  beforeDestroy() {
    off(window, 'resize', this.throttleLazyResizeMethod)
  },
  methods: {
    changeStyle(s) {
      this.style = s || {}
    },
    commitDeviceInfo(module, key, value) {
      this.$store.commit(`${module}/${key}`, value)
    },
    resizeMethod() {
      const w = this.__$body.clientWidth
      const isMobile = w <= DefaultMobileDeviceWidth
      const device = isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP
      const deviceClass = DeviceClassMapping[device]
      this.deviceClass = deviceClass
      this.device = device
      this.commitDeviceInfo('device', 'SET_DEVICE', device)
      this.commitDeviceInfo('editor', 'SET_DEVICE', device)

      this.$nextTick(() => {
        const siteBody = document.querySelector('#site-body')
        const deviceWidth = siteBody ? siteBody.clientWidth : null
        this.commitDeviceInfo('device', 'SET_DEVICE_WIDTH', deviceWidth)
        this.commitDeviceInfo('editor', 'SET_DEVICE_WIDTH', deviceWidth)
      })
    }
  }
}
</script>

<style lang="less">
@prefix-cls: ~'@{namespace}-default';
.@{prefix-cls} {
  min-height: 100%;
  overflow: auto;
  background-color: #293146;
  .@{prefix-cls}-form {
    background-color: #fff;
  }
}
.preview-wrap {
  height: 100%;
}

.site {
  overflow-y: auto;
  height: 100%;
}
</style>
