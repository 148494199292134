<template>
  <el-dialog :visible="$store.state.site.pointTipVisible" :width="$store.getters.isDesktop ? '520px' : '100%'" class="point-store-tip" :show-close="false" append-to-body>
    <div class="text-black">
      <div class="flex items-center title">
        <i class="el-icon-warning text-danger mr-2" />
        <span>{{ $t('siteBuild.vipUserInfoWidget.pointTip') }}</span>
      </div>
      <i class="el-icon-close font-bold cursor-pointer" @click="cancel" />
      <div class="mt-5 text-[#333] text-[16px] leading-7 word-break">
        <div>{{ $t('siteBuild.vipUserInfoWidget.tip1') }}</div>
        <div>{{ $t('siteBuild.vipUserInfoWidget.tip2') }}</div>
        <div>{{ $t('siteBuild.vipUserInfoWidget.tip3') }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui'
export default {
  name: 'VipPointsStoreTipDialog',
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    cancel() {
      this.$store.commit('site/SET_POINT_TIP_VISIBLE', false)
    }
  }
}
</script>

<style lang="less">
.point-store-tip {
  .word-break {
    word-break: break-word;
  }
  .el-dialog__header {
    display: none;
  }
  .title {
    font-size: 22px;
  }
  .el-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
  }
}
</style>
