<template>
 <div class="nav-add-wrap">
   <edit-popover ref="$popover" :target="current" :keys="[targetKey, targetIndex]" :site="site" target-key="navs" :target-index="currentIndex" />
    <menu-item v-for="(n, i) in model.navs" :key="i" class="pt-0">
      <div class="navs mb-2">
        <div class="nav-item flex items-center justify-between">
          <div class="label overflow-hidden truncate" :class="{'icon': true}">{{n.label}}</div>
          <div class="right flex items-center">
            <div class="flex justify-center items-center w-24px h-24px text-textColor-3 hover:text-textColor-5 hover:bg-fillColor-2 cursor-pointer" @click="editNav(n, i)">
              <icon-edit class="text-14" />
            </div>
            <div class="flex justify-center items-center w-24px text-textColor-3 h-24px hover:text-textColor-5 hover:bg-fillColor-2 cursor-pointer" @click="deleteNav(i)">
              <icon-delete class="text-14 text-white" />
            </div>
          </div>
        </div>
      </div>
    </menu-item>
    <menu-item v-if="maxLength > model.navs.length" class="mt-4 pt-0">
      <div class="add-nav">
        <div class="btn flex items-center justify-center" @click="addNav">
          <icon-add />
          <span class="ml-2 text-white text-12">{{$t('head.addnav')}}</span>
        </div>
      </div>
    </menu-item>
 </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import EditPopover from "~/site/components/editMenu/editPopover.vue";
import iconAdd from '@/assets/svg/icon/iconUpload.svg'
import iconEdit from '@/assets/svg/icon/iconEdit.svg'
import iconDelete from '@/assets/svg/icon/iconDelete.svg'
import MenuItem from '~/components/menu/menuItem'
import tools from '~/utils'
import {insertStep} from "~/utils/event";
import {generateDefaultBackground} from "~/site/model/helper";

export default {
  name: "NavAdd",
  components: {
    MenuItem,
    iconDelete,
    iconAdd,
    iconEdit,
    EditPopover
  },
  props:{
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    maxLength: {
      type: Number,
      default: 99
    },
    targetKey: {
      type: String,
      default: ''
    },
    targetIndex: {
      type: [Number, String],
      default: ''
    },
    model: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  data () {
    return {
      editIndex: null,
      current: {},
      currentIndex: -1,
      navs: [
        {
          key: 1,
          label: '主页',
          url: '',
        },
        {
          key: 2,
          label: '产品目录',
          url: '',
        },
        {
          key: 3,
          label: '联系方式',
          url: '',
        },
      ],
    }
  },
  methods: {
    addNav () {
      const navs = cloneDeep(this.model.navs)
      insertStep(this, [this.targetKey, this.targetIndex, 'navs'], navs)
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
      const id = tools.createId(navs, 'key')
      const option = {
        key: id,
        label: `名称${id}`,
        navType: '1',
        url: '',
        model: '',
        page: '',
        icon: generateDefaultBackground()
      }
      this.model.navs.push(option)
    },
    deleteNav (i) {
      const navs = cloneDeep(this.model.navs)
      this.model.navs.splice(i,1)
      insertStep(this, [this.targetKey, this.targetIndex, 'navs'], navs)
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
    },
    editNav (n, i) {
      this.current = n
      this.currentIndex = i
      this.$refs.$popover.init()
    }
  }
}
</script>

<style lang='less' scoped>
.nav-add-wrap {
    .navs {
        .nav-item {
          height: @base-height;
          background: rgba(255, 255, 255, 0.05);
          padding: 0 8px;
            .label {
                font-size: 12px;
                color: @text-5;
                font-weight: 400;
                padding-left: 16px;
                position: relative;
                height: 18px;
                line-height: 18px;
            }
            .label.icon::before {
                display: block;
                content: '';
                position: absolute;
                top: 7px;
                left: 0;
                width: 4px;
                height: 4px;
                background: #7B66F7;
                border-radius: 100%;
            }
        }
    }
    .add-nav {
      width: 100%;
      .btn {
        line-height: @base-height;
        height: 32px;
        padding: 0 16px;
        cursor: pointer;
        border: 1px dashed rgba(255, 255, 255, 0.3);
        border-radius: 2px;
        &:hover {
          background-color: @primary-color-light;
          border-color: @primary-1;
        }
      }
    }
}

</style>
